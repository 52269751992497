<template>
  <div>
    <b-card>
      <b-row>
        <!-- <b-col>
          <p class="text-justify">
            Contrary to popular belief, Lorem Ipsum is not simply random text. It  has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at  Hampden-Sydney College in Virginia, looked up one of the more obscure  Latin words, consectetur, from a Lorem Ipsum passage, and going through  the cites of the word in classical literature, discovered the  undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33  of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by  Cicero
          </p>
        </b-col> -->
        <b-col>
          <b-col class="mb-2">
            <b-alert
              variant="warning"
              show
              class="p-2"
            >
              {{ $t('invest-minimum-deposit',{amount:currency==='IRR'?10+' '+$t('million')+' '+$t('rial'):'150$'}) }}
            </b-alert>
          </b-col>
          <b-row>
            <b-col
              xs="12"
              lg="4"
            >
              <b-form-group
                label-for="currency"
                :label="$t('currency')"
              >
                <b-form-select
                  id="currency"
                  v-model="currency"
                  text-field="text"
                  value-field="value"
                  :options="currencies"
                  :placeholder="$t('currency')"
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              lg="4"
            >
              <b-form-group
                :label="$t('invest-amount')"
                label-for="hear-description"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('invest-amount')"
                  rules="required"
                >

                  <b-form-input
                    v-model="amount"
                    name="hear-description"
                    :placeholder="$t('invest-amount')+ ` (${currency})`"
                    autocomplete="nope"
                    @keypress="onlyNumbers($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              lg="4"
            >
              <b-form-group
                label-for="duration"
                :label="$t('duration')"
              >
                <b-form-select
                  id="duration"
                  v-model="duration"
                  text-field="text"
                  value-field="value"
                  :options="durations"
                  :placeholder="$t('currency')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-if="currency === 'USD' ? amount <= 100000 : amount<=50000000000"
            class="d-flex justify-content-around flex-wrap"
          >
            <div class="text-center my-1">
              <span>{{ $t('annual-interest') }}</span>
              <br>
              <strong v-if="findedPlan">{{ findedPlan.annual_interest }}%</strong>
              <small v-else>{{ $t('complete-the-form-to-calculate') }}</small>
            </div>
            <div class="text-center my-1">
              <span>{{ $t('total-interest') }}</span>
              <br>
              <strong v-if="findedPlan">{{ findedPlan.annual_interest/12*duration }}%</strong>
              <small v-else>{{ $t('complete-the-form-to-calculate') }}</small>
            </div>
            <div class="text-center my-1">
              <span>{{ $t('payment') }}</span>
              <br>
              <strong v-if="findedPlan">{{ $t('every') }} {{ findedPlan.payment }} {{ $t('month') }}</strong>
              <small v-else>{{ $t('complete-the-form-to-calculate') }}</small>
            </div>
          </div>
          <div v-else>
            {{ $t('contact-supports-if-you-want-invest-above',{amount:currency === 'USD'?numberWithCommas(100000)+'$':numberWithCommas(50000000000)+'IRR'}) }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { alphanumeric } from '@/mixins/alphanumeric'
import { dollarPlans, rialPlans } from '../../consts.ts'
import { numberWithCommas } from '@/utils/index.js'

export default {
  mixins: [alphanumeric],
  props: {
    currencies: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      numberWithCommas,
      currency: '',
      duration: '',
      durations: [
        { text: '6 Month', value: 6 },
        { text: '1 Year', value: 12 },
        { text: '2 Year', value: 24 },
        { text: '3 Year', value: 36 },
      ],
      amount: '',
    }
  },
  computed: {
    findedPlan() {
      const p = this.findPlan(this.currency === 'USD' ? this.amount : this.amount / 10000000, this.duration, this.currency === 'USD' ? dollarPlans : rialPlans)
      this.$emit('change', {
        currency: this.currency,
        amount: this.amount,
        duration: this.duration,
        annual_interest: p ? p.annual_interest : null,
        payment: p ? p.payment : null,
      })
      return p
    },
  },
  mounted() {
    this.currency = this.currencies[0].value
  },
  methods: {
    findPlan(amount, duration, plansList) {
      // eslint-disable-next-line no-restricted-syntax
      for (const plan of plansList) {
        if (amount >= plan.min_balance && amount <= plan.max_balance) {
          const durationPlan = plan.durations.find(d => d.duration === duration)
          if (durationPlan) {
            return {
              annual_interest: durationPlan.annual_interest,
              payment: durationPlan.payment,
            }
          }
        }
      }
      return null // Return null if no matching plan is found
    },
  },
}
</script>
