<template>
  <div>
    <b-card>
      <InvestPlantCalculator
        :currencies="
          [
            { text: 'US Dollar (USD)', value: 'USD' },
            { text: 'Iran Rial (IRR)', value: 'IRR' },
          ].filter((item) => item.value === $route.params.type)
        "
        @change="getInvestData"
      />
      <b-col>
        <p
          class="text-center"
          style="font-weight: bold; font-size: 20px"
        >
          {{ $t('payment_method') }}
        </p>
        <b-button-group class="w-100 mb-2">
          <b-button
            :variant="isFiat ? 'info' : 'success'"
            class="w-50"
            @click="changeDepositType('IRR')"
          >
            <div
              :style="
                'direction:' +
                  ($store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr') +
                  ' !important;'
              "
            >
              {{ $t("deposit") }} {{ $t("rial") }} <br>
              (IRR)
            </div>
          </b-button>
          &nbsp;
          <b-button
            :variant="
              isFiat === null ? 'success' : !isFiat ? 'info' : 'success'
            "
            class="w-50"
            @click="changeDepositType('USDT')"
          >
            <span>
              {{ $t("deposit") }} {{ $t("tether") }} <br>
              (USDT)
            </span>
          </b-button>
        </b-button-group>
      </b-col>
      <b-col v-if="isFiat && isFiat !== null">
        <h1
          v-if="amount"
          class="text-center my-2"
        >
          {{ $t("deposit-amount") }}:
          {{
            numberWithCommas(
              toFixed(
                $route.params.type === "USD"
                  ? amount * (exchangeRate.buy * 10) * 1.015
                  : amount,
                0
              )
            )
          }}
          {{ $t("IRR") }}
        </h1>
        <p
          v-if="amount"
          class="text-center"
        >
          {{ $t('please_deposit_amount_to_account_below') }}
        </p>
        <b-alert
          show
          variant="danger"
          class="p-2"
        >
          {{ $t("contact-support-with-ticket") }}
        </b-alert>
        <b-card
          v-if="false"
          class="bg-card text-white w-100 w-md-50 mx-auto"
        >
          <b-row>
            <b-col class="text-center text-lg-right w-75">
              <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                $t("cardNumber")
              }}</span>
              <span class="pa-2 d-block font-weight-bolder">{{
                adminCardInfo.card_number
              }}</span>
              <br>
              <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                $t("accountNumber")
              }}</span>
              <!-- <span class="pa-2 d-block font-weight-bolder">{{
                adminCardInfo.account_number
              }}</span>
              <br> -->
              <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                $t("iban")
              }}</span>
              <span class="pa-2 d-block font-weight-bolder">{{
                adminCardInfo.iban
              }}</span>
              <br>
              <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                $t("card_name")
              }}</span>
              <!-- <span class="pa-2 d-block font-weight-bolder">{{
                adminCardInfo.card_name
              }}</span>
              <br> -->
              <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                $t("bank_name")
              }}</span>
              <span class="pa-2 d-block font-weight-bolder">
                {{ adminCardInfo.bank_name }}</span>
              <br>
              <span v-if="adminCardInfo.deposit_transaction_number">
                <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                  $t("deposit-id")
                }}</span>
                <span class="pa-2 d-block font-weight-bolder">
                  {{ adminCardInfo.deposit_transaction_number }}</span>
              </span>
            </b-col>
            <b-col
              cols="1"
              class="text-center text-lg-right w-25 d-none d-lg-block"
            >
              <!-- <span class="pa-2 d-block">:</span> -->
              <!-- <br> -->
              <!-- <span class="pa-2 d-block">:</span> -->
              <!-- <br> -->
              <span class="pa-2 d-block">:</span>
              <br>
              <span class="pa-2 d-block">:</span>
              <br>
              <span class="pa-2 d-block">:</span>
              <br>
              <span
                v-if="adminCardInfo.deposit_transaction_number"
                class="pa-2 d-block"
              >:</span>
            </b-col>
            <b-col
              cols="3"
              class="text-center text-lg-right w-25 d-none d-lg-block"
            >
              <span class="pa-2 text-nowrap d-block font-weight-bolder">{{
                $t("cardNumber")
              }}</span>
              <!-- <br>
              <span class="pa-2 text-nowrap d-block font-weight-bolder">{{
                $t("accountNumber")
              }}</span> -->

              <br>
              <span class="pa-2 d-block font-weight-bolder">{{
                $t("iban")
              }}</span>
              <!-- <br>
              <span class="pa-2 d-block font-weight-bolder">{{
                $t("card_name")
              }}</span> -->
              <br>
              <span class="pa-2 d-block font-weight-bolder">{{
                $t("bank_name")
              }}</span>
              <br>
              <span
                v-if="adminCardInfo.deposit_transaction_number"
                class="pa-2 d-block font-weight-bolder"
              >{{ $t("deposit-id") }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        v-if="walletAddress && !isFiat && isFiat !== null"
        cols="12"
      >
        <h1
          v-if="amount"
          class="text-center my-2"
        >
          <!-- {{ $t('deposit-amount') }}: {{ numberWithCommas( toFixed($route.params.type === 'USD'? amount:amount/(exchangeRate.sell*10),2)) }} {{ $t('USD') }} -->
          {{ $t("deposit-amount") }}:
          {{
            numberWithCommas(
              toFixed(
                $route.params.type === "USD"
                  ? amount
                  : (amount / (exchangeRate.sell * 10)) * 1.015,
                2
              )
            )
          }}
          {{ $t("USDT") }}
        </h1>
        <p class="text-center">
          {{ $t("please_deposit_amount_to_wallet_below") }}
        </p>
        <b-form-group
          id="popover-manual-1"
          label-for="walletAddress"
          :label="$t('walletAddress')"
        >
          <copy-to-clipboard
            :text="walletAddress"
            @copy="pop1 = true"
          >
            <b-input-group class="mb-2">
              <b-form-input
                id="walletAddress"
                v-model="walletAddress"
                readonly
                name="walletAddress"
                :placeholder="$t('walletAddress')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CopyIcon"
                  size="20"
                />
              </b-input-group-append>
            </b-input-group>
          </copy-to-clipboard>
          <b-popover
            target="popover-manual-1"
            :show.sync="pop1"
            triggers="click"
            placement="bottom"
          >
            {{ $t("copied") }}
          </b-popover>
        </b-form-group>
        <div
          class="my-2 text-center mx-auto d-flex justify-content-center align-center"
        >
          <qrcode-vue
            v-if="walletAddress"
            :value="walletAddress"
            :background="isDarkTheme ? '#283046' : '#fff'"
            :foreground="isDarkTheme ? '#fff' : '#283046'"
            :size="qrsize"
            class="qrcode"
          />
        </div>
      </b-col>
      <validation-observer
        v-if="isFiat !== null"
        ref="depositform"
      >
        <b-form>
          <b-row>
            <b-col
              v-if="!isFiat"
              xs="12"
              lg="6"
            >
              <p class="mt-2">
                {{ $t('please_enter_transaction_id_and_screenshot') }}
              </p>
              <b-form-group :label="$t('transaction_id')">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('txId')"
                  rules="required"
                >
                  <b-form-input
                    v-model="txId"
                    :placeholder="$t('transaction_id')"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              lg="6"
              class="p-0"
            >
              <p
                :style="'visibility:' + (isFiat ? 'unset' : 'hidden')"
                class="mt-2"
              >
                {{ $t('upload_receipt') }}
              </p>
              <b-form-group
                label-size="lg"
                :label="$t('deposit_Receipt')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('deposit_Receipt')"
                  :rules="'required|size:' + MAX_IMAGE_UPLOAD_SIZE"
                >
                  <b-form-file
                    id="file-large"
                    v-model="file"
                    size="lg"
                    accept="image/png, image/jpg, image/jpeg"
                    :placeholder="$t('fiat_wallet_deposit_receipt_file')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-button
        v-if="isFiat !== null"
        :disabled="
          !amount ||
            !currency ||
            !duration ||
            file.length <= 0 ||
            (!isFiat && !txId)
        "
        class="w-100"
        variant="success"
        @click="submit"
      >
        <span v-if="!submitLoading">{{ $t("submit") }}</span>
        <b-spinner
          v-else
          small
          type="grow"
        />
      </b-button>
    </b-card>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import CopyToClipboard from 'vue-copy-to-clipboard'
import { numberWithCommas, resizeImage, toFixed } from '@/utils'
import InvestApis from '../service/apis'
import InvestPlantCalculator from './components/investPlantCalculator.vue'
import CurrencyApis from '@/services/apis/currency'

const invest = new InvestApis()
const currency = new CurrencyApis()

export default {
  components: { QrcodeVue, InvestPlantCalculator, CopyToClipboard },

  data() {
    return {
      numberWithCommas,
      toFixed,
      file: [],
      amount: '',
      currency: '',
      duration: '',
      submitLoading: false,
      txId: '',
      qrsize: 150,
      walletAddress: 'TBEEJFMRgZC6gZS2cdNsA8ExZP2LrpLGGt',
      pop1: false,
      isFiat: null,
      exchangeRate: {},
      adminCardInfo: {
        account_number: '',
        deposit_transaction_number: '',
        card_number: '',
        iban: '',
        card_name: '',
        bank_name: '',
      },
    }
  },
  computed: {
    MAX_IMAGE_UPLOAD_SIZE() {
      return process.env.VUE_APP_MAX_IMAGE_UPLOAD_SIZE
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    },
  },
  mounted() {
    currency.exchangeRate('IRT').then(res => {
      this.exchangeRate = res.data.results
    })
  },
  methods: {
    getInvestData(data) {
      this.amount = data.amount
      this.currency = data.currency
      this.duration = data.duration
    },
    changeDepositType(type) {
      this.isFiat = type === 'IRR'
    },
    async submit() {
      this.submitLoading = true
      const compressedFile = await resizeImage(this.file)
      await invest
        .deposit({
          amount: this.amount,
          img: compressedFile,
          currency: this.currency === 'IRR' ? 'IRR' : 'USDT',
          duration: this.duration,
          mode: this.$route.params.type === 'IRR' ? 'IRR' : 'USD',
          ...(this.isFiat ? {} : { tx_id: this.txId }),
        })
        .then(res => {
          this.$swal({
            title: res.data?.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          this.$router.push({ name: 'investModule-my-plan' })
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
  },
}
</script>

<style scoped>
::v-deep .qrcode {
  width: 150px;
  height: 150px;
}
::v-deep .qrcode canvas {
  width: 100% !important;
}
.bg-card {
  background-color: #00b983;
}
</style>
